<template>
  <smart-church-layout page="Members Demographics">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
    <!-- <skeletonLoader v-if="overlay" /> -->

    <v-container class="my-8">
      <v-row justify="center" align-items="center">
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ employedMembers.length }}</p>
            <p class="fs-5 text-secondary text-center">Members Employed</p>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ unEmployedMembers.length }}</p>
            <p class="fs-5 text-secondary text-center">Members Unemployed</p>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" align-items="center">
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ singleMembers.length }}</p>
            <p class="fs-5 text-secondary text-center">Single Members</p>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ marriedMembers.length }}</p>
            <p class="fs-5 text-secondary text-center">Married Members</p>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ maleCount.length }}</p>
            <p class="fs-5 text-secondary text-center">Male Members</p>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ femaleCount.length }}</p>
            <p class="fs-5 text-secondary text-center">Female Members</p>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-row justify="center" align-items="center">
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ underForty.length }}</p>
            <p class="fs-5 text-secondary text-center">Members under the age of 40</p>
          </v-card>
        </v-col>
        <v-col cols="6" lg="4">
          <v-card class="text-center pa-4">
            <p class="mb-0 text-center">
              <v-icon color="primary" class="fs-7 mx-auto w-50"
                >mdi-account-group-outline</v-icon
              >
            </p>
            <p class="fs-1 text-center mb-0">{{ overForty.length }}</p>
            <p class="fs-5 text-secondary text-center">Members over the age of 40</p>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </smart-church-layout>
</template>

<script>
import SmartChurchLayout from "../../components/Layouts/SmartChurchLayout.vue"
// import { catchAxiosExceptions } from "../../__helpers/handelExceptions"
import skeletonLoader from "../../components/FormSkeletonLoader.vue"

export default {
  components: { SmartChurchLayout, skeletonLoader },

  data() {
    return {
      overlay: true,
      memberDetails: [],
      employedMembers: [],
      unEmployedMembers: [],
      singleMembers: [],
      marriedMembers: [],
      underForty: [],
      overForty: [],
      femaleCount: [],
      maleCount: []
    }
  },

  methods: {
    getMembersDetails() {
      this.$http
        .get("admin/membersDemography")
        .then((response) => {
          this.memberDetails = response.data.data
          let membersEmployed = []
          let membersUnemployed = []
          let memberSingle = []
          let membersMarried = []
          let membersUnderForty = []
          let membersOverForty = []

          let maleMembers = []
          let femaleMembers = []
          // console.log(this.memberDetails)
          this.memberDetails.forEach((memberDetail) => {
            if (memberDetail.sex == "Male") {
              maleMembers.push(memberDetail)
            }
            this.maleCount = maleMembers

            if (memberDetail.sex == "Female") {
              femaleMembers.push(memberDetail)
            }
            this.femaleCount = femaleMembers
            
            // Employed or unemployed members
            if (memberDetail.employment_status == "Employed") {
              membersEmployed.push(memberDetail)
            }
            this.employedMembers = membersEmployed

            if (memberDetail.employment_status == "Unemployed") {
              membersUnemployed.push(memberDetail)
            }
            this.unEmployedMembers = membersUnemployed

            // Single and Married Members
            if (memberDetail.marital_status == "Single") {
              memberSingle.push(memberDetail)
            }
            this.singleMembers = memberSingle

            if (memberDetail.marital_status == "Married") {
              membersMarried.push(memberDetail)
            }
            this.marriedMembers = membersMarried

            // Members Under 40 and Over 40
            let currentDate = new Date()
            let memberDateOfBirth = new Date(memberDetail.date_of_birth)
            let membersBirthYear = memberDateOfBirth.getFullYear()
            let currentYear = currentDate.getFullYear()
            let age = currentYear - membersBirthYear

            if (age < 40) {
              // console.log(age)
              membersUnderForty.push(age)
            }
            // console.log(membersUnderForty)
            this.underForty = membersUnderForty
            if (age >= 40) {
              // console.log(age)
              membersOverForty.push(age)
            }
            // console.log(membersOverForty)
            this.overForty = membersOverForty
          })
          this.overlay = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },

  mounted() {
    this.getMembersDetails()
  },
}
</script>

<style scoped>
h3 {
  font-size: 24px !important;
}

.fs-7 {
  font-size: 3rem !important;
}

/* .v-data-table__wrapper > table > thead > tr:nth-child(even) {
  background-color: #fde4e4 !important;
}
.v-data-table__wrapper > table > thead > tr:nth-child(odd) {
  background-color: #fff !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  color: rgb(204, 89, 89) !important;
  font-size: 1em !important;
}
tr:nth-child(odd) {
  background-color: #fff !important;
}
tr:nth-child(even) {
  background-color: #fde4e4 !important;
} */
</style>
